import React, { useState } from "react";
import { Theme, GlobalStyle } from "../../global-style";
import { ModalContext } from "./modal-context.js";

export default function Layout({ children }) {
    const [context, setContext] = useState(false);
    return (
      <ModalContext.Provider value={[context, setContext]}>
        <Theme>
          <GlobalStyle />
          {children}
        </Theme>
      </ModalContext.Provider>
    )
  }