import React from "react";
import styled from "styled-components";

const GridWrapper = styled.div`
    display: block;
    margin: 0 auto;
    height: auto;
`
const StyledGrid = styled.div`
    display: grid;
    font-size: inherit;
    grid-template-columns: repeat(12, [col-start] 1fr);
    margin: 0 auto;
    max-width: 1600px;
    gap: 10px;
    padding: 0 12px;
    ${props => props.theme.device.tablet} {
        gap: 20px;
        padding: 0 40px;
    }
    ${props => props.theme.device.laptopLg} {
        padding: 0 48px;
    }
`;

const Grid = ({ children, ...props }) => (
    <GridWrapper>
        <StyledGrid {...props}>
            {children}
        </StyledGrid>
    </GridWrapper>
);

export default Grid;