import AvertaExtraboldWoff from "./fonts/Averta-Extrabold.woff";
import AvertaExtraboldWoff2 from "./fonts/Averta-Extrabold.woff2";
import AvertaRegularWoff from "./fonts/Averta-Regular.woff";
import AvertaRegularWoff2 from "./fonts/Averta-Regular.woff2";
import AvertaSemiboldWoff from "./fonts/Averta-Semibold.woff";
import AvertaSemiboldWoff2 from "./fonts/Averta-Semibold.woff2";
import React from "react";
import { ThemeProvider } from "styled-components";
import { createGlobalStyle } from "styled-components";

export const themeObject = {
  	color: {
		bg: "#010912",
		textPrimary: "#D2DAE4",
		textSecondary: "#5D7696",
		textHighContrast: "#FFF",
		textLink: "#6AA7F8",
		blue: "#0263C5",
		uiCardGround: "#071C37",
		uiCardEdge: "#11315F",
		panelRGB: "0,24,48",
		uiPillGround: "#6AA7F8",
		white: "#fff",
		accentGreen: "#55B835",
		accentRed: "#EA4225",
		accentOrange: "#ED6C2C",
		categoryPeach: "#A86121",
		categoryPurple: "#8319F5",
		categoryLime: "#6C7220",
		categoryAqua: "#377D7C",
		categoryTan: "#896D21",
		categoryBlue: "#285FAB",
		categoryOrange: "#AF4D1E",
		categoryGreen: "#2E6B1F",
		categoryPeachRGB: "184,96,16",
		categoryPurpleRGB: "93,15,174",
		categoryLimeRGB: "162,171,53",
		categoryAquaRGB: "86,188,187",
		categoryTanRGB: "207,164,54",
		categoryBlueRGB: "61,137,247",
		categoryOrangeRGB: "236,104,44",
		categoryGreenRGB: "73,162,49",
		categoryPinkRGB: "193, 40, 246",
		categoryRed: "#7B160D",
		categoryViolet: "#3A13AC",
		categoryMagenta: "#A01F8A",
		categoryBrown: "#81521A",
		categoryLemon: "#948629",
		categoryRuby: "#991D41",
  	},
  	fontSize: {
    	xsmall: "0.75em", 		// 12pt
		small: "0.875em", 		// 14pt
		regular: "1em",			// 16pt
		regular2: "1.125em",	// 18pt
		medium: "1.25em",		// 20pt
		medium2: "1.5em",		// 24pt
		large: "2em",			// 32pt
		xlarge: "2.5em",		// 40pt
		xxlarge: "3em",			// 48pt
		xxxlarge: "3.8em"			// 64pt
	},
	lineHeight: {
		xsmall: "1.5",
		small: "1.5",
		regular: "1.4",
		regular2: "1.4",
		medium: "1.4",
		medium2: "1.4",
		large: "1.3",
		xlarge: "1.3",
		xxlarge: "1.2",
		xxxlarge: "1.15"
	},
	spacing: [
		"0px",
		"4px",
		"8px",
		"12px",
		"16px",
		"20px",
		"24px",
		"32px",
		"48px",
		"64px",
		"72px",
		"140px"
	],
	device: {
		mobileSm: "@media screen and (min-width: 320px)",
		mobileMd: "@media screen and (min-width: 375px)",
		mobileLg: "@media screen and (min-width: 425px)",
		tablet: "@media screen and (min-width: 768px)",
		laptop: "@media screen and (min-width: 1024px)",
		laptopLg: "@media screen and (min-width: 1440px)",
		desktop: "@media screen and (min-width: 2560px)",
	}
}

export const Theme = ({ children }) => (
	<ThemeProvider theme={themeObject}>{children}</ThemeProvider>
);


export const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'Averta';
        src: url(${AvertaExtraboldWoff2}) format('woff2'),
             url(${AvertaExtraboldWoff}) format('woff');
        font-weight: 800;
        font-style: normal;
		font-display: fallback;
    }
    @font-face {
        font-family: 'Averta';
        src: url(${AvertaRegularWoff2}) format('woff2'),
             url(${AvertaRegularWoff}) format('woff');
        font-weight: 400;
        font-style: normal;
		font-display: fallback;
    }
    @font-face {
        font-family: 'Averta';
        src: url(${AvertaSemiboldWoff2}) format('woff2'),
             url(${AvertaSemiboldWoff}) format('woff');
        font-weight: 600;
        font-style: normal;
		font-display: fallback;
    }

    *,
    *::before,
    *::after {
      box-sizing: border-box;
			margin: 0;
			padding: 0;
    }

	html {
		font-size: 100%;
	}

    body, button {
        font-family: Averta, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
        margin: 0;
				// text-rendering: optimizeLegibility;
				// -webkit-font-smoothing: antialiased;
				background-color: ${themeObject.color.bg};
				color: ${themeObject.color.textPrimary};
    }

	a {
		color: ${themeObject.color.textLink};
		text-decoration: none;
	}

	:root {
		color-scheme: dark;
	}
  blockquote {
    border-left: 3px solid rgba(255, 255, 255, .12);
    margin: 0 0 2em 1em;
    padding: 0.5em 0 0.5em 1em;
    color: ${props => props.theme.color.textSecondary};
  }
  blockquote > p {
    color: #C0C2CC;
    font-weight: 400;
    font-style: italic;
  }
  blockquote > p :before {
    content: '“';
  }
  blockquote > p :after {
    content: '”';
  }
  blockquote > div {
    color: #C0C2CC;
    font-weight: 600;
  }
`
