import React from "react";
import styled from "styled-components";
import Grid from "../shared/grid";
import Logo from "./logo";
import { motion, useScroll } from "framer-motion";
import { Link } from "gatsby";
import ButtonElement from "../shared/button-element";
import { themeObject } from "../../global-style";

const NAVBAR_Y_OFFSET = -50;
const QRCODE_Y_OFFSET = 50;
const PAGE_SCROLL_OFFSET = 1500;

const Navbar = () => {
    const { scrollY } = useScroll();
    const [visible, setVisible] = React.useState(false);

    function update() {
        if (scrollY?.current < PAGE_SCROLL_OFFSET ) {
            setVisible(false);
        } else if (scrollY?.current > PAGE_SCROLL_OFFSET && scrollY?.current > scrollY?.prev) {
            setVisible(true);
        }
    }
    React.useEffect(() => {
        return scrollY.onChange(() => update());
    });

    const variants = {
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: NAVBAR_Y_OFFSET }
    };

    return (
        <StyledNavbar
            variants={variants}
            animate={visible ? "visible" : "hidden"}
            transition={{ type: 'spring', bounce: 0, duration: 0.3 }}
        >
            <Grid>
                <FlexContainer>
                    <StyledLink to="/" tabIndex={!visible ? -1 : 0}><Logo aria-label="Copilot"/></StyledLink>
                    <StyledButton>
                        <ButtonElement href="/download">Download</ButtonElement>
                    </StyledButton>
                </FlexContainer>
            </Grid>
        </StyledNavbar>
    )
}


const StyledButton = styled.div`
    pointer-events: auto;
`
const StyledLink = styled(Link)`
    pointer-events: auto;
`
const StyledNavbar = styled(motion.nav)`
    pointer-events: none;
    width: 100%;
    opacity: 0;
    transform: translateY(${NAVBAR_Y_OFFSET}px);
    display: absolute;
    position: fixed;
    top: 0;
    z-index: 3;
    padding: 16px 0;
    background-color: rgba(1, 9, 18, 1.0);
    background-image: none;
    box-shadow: inset 0px -1px 0px #051830;
    ${props => props.theme.device.tablet} {
        background-color: transparent;
        background-image: linear-gradient(
            0deg,
            hsl(212deg 89% 4% / 0%) 0%,
            hsl(212deg 89% 4% / 13%) 15%,
            hsl(212deg 89% 4% / 20%) 22%,
            hsl(212deg 89% 4% / 28%) 28%,
            hsl(212deg 89% 4% / 33%) 33%,
            hsl(212deg 89% 4% / 37%) 37%,
            hsl(212deg 89% 4% / 42%) 42%,
            hsl(212deg 89% 4% / 46%) 46%,
            hsl(212deg 89% 4% / 50%) 50%,
            hsl(212deg 89% 4% / 54%) 54%,
            hsl(212deg 89% 4% / 58%) 58%,
            hsl(212deg 89% 4% / 63%) 63%,
            hsl(212deg 89% 4% / 67%) 67%,
            hsl(212deg 89% 4% / 72%) 72%,
            hsl(212deg 89% 4% / 78%) 78%,
            hsl(212deg 89% 4% / 85%) 85%,
            hsl(212deg 89% 4% / 100%) 100%
          );
        box-shadow: none;
        padding: 20px 0;
    }
    ${props => props.theme.device.laptopLg} {
        padding: 32px 0;
    }
    // @supports(backdrop-filter: opacity( 1.0 )) {
    //     backdrop-filter: saturate(180%) blur(20px);
    //     background: rgba(1, 9, 18, 0.7);
    //     background-image: none;
    // }
`
const FlexContainer = styled.div`
    grid-column: col-start / span 12;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export default Navbar