import React from "react";
import styled, { css } from "styled-components";
import { Link } from "gatsby";
import { motion } from "framer-motion";

const ButtonElement = ({ href, small, children, outbound, ...props }) => {
  return (
    <>
        {
          outbound ? 
            (<OutboundLink whileHover={{scale: 1.05}} href={href} {...props}>{children}</OutboundLink>) : 
            (<MotionLink whileHover={{scale: 1.05}} to={href} {...props}>{children}</MotionLink>)
        }
    </>
  );
};

const ButtonStyle = css`
  border: none;
  cursor: pointer;
  background-color: ${(props) => props.theme.color.uiPillGround};
  color: ${(props) => props.theme.color.bg};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  scale: 1;
  &:focus {
    outline: none;
  }
  &:focus-visible {
    box-shadow: 0 0 0 2pt ${(props) => props.theme.color.bg},
      0 0 0 5pt ${(props) => props.theme.color.textSecondary};
  }
  font-weight: 600;
  padding: 8px 12px;
  border-radius: 5px;
  letter-spacing: -0.01em;
  --border-bottom-width: 1px; --border-color: rgba(0, 0, 0, 0.4); --border-left-width: 1px; --border-right-width: 1px; --border-style: solid; --border-top-width: 1px; opacity: 1; border-radius: 8px; box-shadow: rgba(0, 0, 0, 0.41) 0px -1px 1px -1px inset, rgba(0, 0, 0, 0.2) 0px 2px 4px 0px, rgba(0, 0, 0, 0.21) 0px -1px 0px 1px inset, rgba(133, 183, 250, 0) 0px 8px 16px 0px;
`;

const ButtonWithGradientStyle = css`
  border: none;
  cursor: pointer;
  background-color: ${(props) => props.theme.color.uiPillGround};
  color: ${(props) => props.theme.color.bg};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  scale: 1;
  &:focus {
    outline: none;
  }
  &:focus-visible {
    box-shadow: 0 0 0 2pt ${(props) => props.theme.color.bg},
      0 0 0 5pt ${(props) => props.theme.color.textSecondary};
  }
  font-weight: 600;
  padding: 8px 12px;
  border-radius: 5px;
  letter-spacing: -0.01em;
  --border-bottom-width: 1px; --border-color: rgba(0, 0, 0, 0.4); --border-left-width: 1px; --border-right-width: 1px; --border-style: solid; --border-top-width: 1px; background: linear-gradient(rgb(255, 255, 255) 5.5482%, rgb(168, 204, 250) 100%); opacity: 1; border-radius: 8px; box-shadow: rgba(0, 0, 0, 0.41) 0px -1px 1px -1px inset, rgba(0, 0, 0, 0.2) 0px 2px 4px 0px, rgba(0, 0, 0, 0.21) 0px -1px 0px 1px inset, rgba(133, 183, 250, 0) 0px 8px 16px 0px;
`;

const LinkElement = styled(Link)`
  ${ButtonWithGradientStyle}
`;
const MotionLink = motion(LinkElement);

const OutboundLink = styled(motion.a)`
  ${ButtonStyle}
`;

export default ButtonElement;
