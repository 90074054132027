import styled, { css } from "styled-components";

import React from "react";

// base css
const BaseHeadingStyles = css`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${props => props.dense ? '0' : '32px'};
  margin-top: 0;
  max-width: 850px;
  font-size: ${props => props.theme.fontSize.large};
  // line-height: ${props => props.theme.lineHeight.large};

  font-size: 2.2em;
  line-height: 1.1;

  font-weight: 600;
  text-align: center;
  grid-column: col-start / span 12;
  color: ${({ theme }) => theme.color.textPrimary};
  letter-spacing: -0.5px;

  ${({ theme }) => theme.device.tablet} {
    grid-column: col-start 2 / span 10;
    font-size: ${props => props.theme.fontSize.xlarge};
    margin-bottom: ${props => props.dense ? '16px' : '48px'};
    line-height: 1.1;
  }
  ${({ theme }) => theme.device.laptop} {
    grid-column: col-start 3 / span 8;
    font-size: ${props => props.theme.fontSize.xxxlarge};
    line-height: ${props => props.theme.lineHeight.xxxlarge};
    margin-bottom: ${props => props.dense ? '24px' : '88px'};
  }
`

// H1
const StyledH1 = styled.h1`
  ${BaseHeadingStyles}
  font-weight: 800;
`
export const H1 = ({ children, ...props }) => (
  <StyledH1 {...props}>
    {children}
  </StyledH1>
);

// H2
const StyledH2 = styled.h2`
  ${BaseHeadingStyles}
  font-size: 27px;
  line-height: 1.3;
  font-weight: 800;
  letter-spacing: -0.3px;
  ${({ theme }) => theme.device.tablet} {
    letter-spacing: -0.5px;
    grid-column: col-start 2 / span 10;
  }
  ${({ theme }) => theme.device.laptop} {
    grid-column: col-start 3 / span 8;
    font-size: ${props => props.theme.fontSize.xxxlarge};
    line-height: ${props => props.theme.lineHeight.xxxlarge};
    margin-bottom: ${props => props.dense ? '24px' : '64px'};
  }
`
export const H2 = ({ children, ...props }) => (
  <StyledH2 {...props}>
    {children}
  </StyledH2>
);

// H3
const StyledH3 = styled.h3`
  ${BaseHeadingStyles}
  font-weight: 400;
  font-size: ${props => props.theme.fontSize.regular2};
  line-height: ${props => props.theme.lineHeight.regular2};
  margin-bottom: 0px;
  letter-spacing: 0em;

  ${({ theme }) => theme.device.tablet} {
    font-weight: 600;
    font-size: ${props => props.theme.fontSize.regular2};
    line-height: ${props => props.theme.lineHeight.regular2};
    text-align: left;
    margin-bottom: 0px;
  }
  ${({ theme }) => theme.device.laptop} {
    font-size: ${props => props.theme.fontSize.large};
    line-height: ${props => props.theme.lineHeight.large};
    margin-bottom: 0px;
  }
`
export const H3 = ({ children, ...props }) => (
  <StyledH3 {...props}>
    {children}
  </StyledH3>
);

// H4
const StyledH4 = styled.h4`
  ${BaseHeadingStyles}
  color: ${props => props.theme.color.textSecondary};
  font-size: ${props => props.theme.fontSize.regular2};
  line-height: ${props => props.theme.lineHeight.regular2};
  letter-spacing: 0em;
  font-weight: 400;

  ${({ theme }) => theme.device.tablet} {
    font-size: ${props => props.theme.fontSize.medium2};
  }
  ${({ theme }) => theme.device.laptop} {
    font-size: ${props => props.theme.fontSize.large};
    line-height: ${props => props.theme.lineHeight.large};
    letter-spacing: -0.017em;
    margin-bottom: 56px;
  }
`

export const H4 = ({ children, ...props }) => (
  <StyledH4 {...props}>
    {children}
  </StyledH4>
);

// H5
export const StyledH5 = styled.h5`
  font-size: ${props => props.theme.fontSize.regular};
  line-height: ${props => props.theme.lineHeight.regular};
  color: ${props => props.theme.color.textPrimary};
  font-weight: 600;
  letter-spacing: -0.017em;
`

export const H5 = ({ children, ...props }) => (
  <StyledH5 {...props}>
    {children}
  </StyledH5>
);

export const WrappingStyledH5 = styled.h5`
  font-size: 1.3em;
  line-height: ${(props) => props.theme.lineHeight.regular};
  color: ${(props) => props.theme.color.textPrimary};
  font-weight: 600;
  letter-spacing: -0.017em;
  width: 100%;
  text-align: center;
  margin: 0 auto;

  ${({ theme }) => theme.device.tablet} {
    width: 600px;
  }
  ${({ theme }) => theme.device.laptop} {
    width: 600px;
  }
`;

export const WrappingH5 = ({ children, ...props }) => (
  <WrappingStyledH5 {...props}>{children}</WrappingStyledH5>
);
